import React from "react"
import Link from "../components/link"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Grid from "@material-ui/core/Grid"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Grid container>
      <Grid
        item
        style={{ minHeight: "calc(100vh - (18.1rem + 5rem))" }}
        xs={12}
        className="text-center"
      >
        <h1 style={{ marginTop: "5em" }}>NOT FOUND</h1>
        <p>The page you requested doesn&#39;t exist</p>
        <Link to="/">
          <p style={{ fontSize: "1.25rem" }} className="font-weight-bold">
            Go Back
          </p>
        </Link>
      </Grid>
    </Grid>
  </Layout>
)

export default NotFoundPage
